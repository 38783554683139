import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d5a55dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt", "onerror"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaceholderImage = _resolveComponent("PlaceholderImage")!

  return (_ctx.state.data && _ctx.hasThumbnail)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _ctx.source,
        alt: _ctx.alt,
        class: "app-img",
        onerror: _ctx.noThumbnail
      }, null, 8, _hoisted_1))
    : (_ctx.type)
      ? (_openBlock(), _createBlock(_component_PlaceholderImage, {
          key: 1,
          type: _ctx.type,
          class: "app-img"
        }, null, 8, ["type"]))
      : (_openBlock(), _createBlock(_component_PlaceholderImage, {
          key: 2,
          type: "no-image",
          class: "app-img"
        }))
}