
import { defineComponent, toRefs, watch, computed, reactive, ref } from 'vue'
import PlaceholderImage from './PlaceholderImage.vue'

export default defineComponent({
  name: 'AppImgWrapper',
  components: {
    PlaceholderImage,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    type: {
      type: String,
    },
  },
  setup (props) {
    const state = reactive({
      data: '',
    })

    const getResource = () => {
      if (props.src) {
        fetch(props.src, {
          method: 'GET',
          mode: 'cors',
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.blob()
          })
          .then((myBlob) => {
            state.data = URL.createObjectURL(myBlob)
          })
          .catch(() => {/* do nothing */}) // eslint-disable-line
      }
    }
    getResource()
    const { src } = toRefs(props)
    watch(src, getResource)

    const hasThumbnail = ref<boolean>(true)
    const noThumbnail = () => {
      hasThumbnail.value = false
    }
    return {
      state,
      source: computed(() => props.src || '/img/document-book.svg'),
      hasThumbnail,
      noThumbnail,
    }
  },
})
